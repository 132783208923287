import request from '@/service/lib/request';

export async function home() {
    return request({
        url: '/statistics/home',
        method: 'get',
    });
}

export async function getOrderList(params) {
    return request({
        url: '/statistics/orderlist',
        method: 'get',
        params,
    });
}
//核销统计汇总
export async function getCheckStatistic(params) {
    return request({
        url: '/statistics/checkpage',
        method: 'get',
        params,
    });
}
//各门店酒水核销统计
export async function getStoreCheckStatistic() {
    return request({
        url: '/statistics/storecheck',
        method: 'get',
    });
}
//各门店酒水核销统计（每日. 每月）
export async function getStoreCheckDateStatistic(params) {
    return request({
        url: '/statistics/storecheckdate',
        method: 'get',
        params,
    });
}
//酒水核销记录
export async function getCheckList(params) {
    return request({
        url: '/statistics/checklist',
        method: 'get',
        params,
    });
}
//各渠道订单统计（每日. 每月）
export async function getSceneOrderDateStatistic(params) {
    return request({
        url: '/statistics/orderscenedate',
        method: 'get',
        params,
    });
}

export async function getShareList(params) {
    return request({
        url: '/statistics/sharelist',
        method: 'get',
        params,
    });
}
